<template>
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0001 6.12524C10.0001 5.92211 9.94324 5.74633 9.82959 5.59802L5.8089 0.34961C5.69525 0.20117 5.56061 0.12695 5.40509 0.12695C5.24951 0.12695 5.11487 0.20117 5.00122 0.34961L0.980589 5.59802C0.866879 5.74634 0.810059 5.92212 0.810059 6.12524C0.810059 6.32824 0.866879 6.5039 0.980589 6.65234C1.09424 6.80078 1.22888 6.875 1.38446 6.875H9.42572C9.58124 6.875 9.71588 6.80078 9.82959 6.65234C9.94324 6.5039 10.0001 6.32824 10.0001 6.12524Z" fill="#333333"/>
    </svg>
</template>

<script>
export default {
  name: 'IconSortUp'
}
</script>

<template>
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0001 0.876713C10.0001 1.07984 9.94324 1.25562 9.82959 1.40393L5.8089 6.65234C5.69525 6.80078 5.56061 6.875 5.40509 6.875C5.24951 6.875 5.11487 6.80078 5.00122 6.65234L0.980589 1.40393C0.866879 1.25561 0.810059 1.07983 0.810059 0.876713C0.810059 0.673713 0.866879 0.498053 0.980589 0.349613C1.09424 0.201173 1.22888 0.126953 1.38446 0.126953H9.42572C9.58124 0.126953 9.71588 0.201173 9.82959 0.349613C9.94324 0.498053 10.0001 0.673713 10.0001 0.876713Z" fill="#ADADAD"/>
    </svg>
</template>

<script>
export default {
  name: 'IconSortDown'
}
</script>

